// Bootstrap
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap-vue/dist/bootstrap-vue.css";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Colours
@import "./colours";

// Fonts
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

// Elements & Classes
body {
  background-color: var(--colour-background);
  color: var(--colour-text);
  font-family: "Poppins", sans-serif;
}

a {
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--colour-border);
}

.bttn {
  border: 0;
  background-color: var(--colour-background);
  border-radius: 4px;
  padding: 4px 10px;
  color: var(--colour-text-muted);

  svg {
    margin-right: 6px;
  }

  &:hover {
    background-color: var(--colour-background-tint);
    cursor: pointer;
    color: inherit;
  }
}
.dark-theme .bttn:hover {
  background-color: var(--colour-background-elevated);
}

.modal-content {
  background-color: var(--colour-background);
  color: var(--colour-text);
}

.modal-content {
  .modal-header,
  .modal-body,
  .modal-footer {
    border: 0;
  }
}
